.container {
    .inner {
        display: flex;
        max-width: 600px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        margin: 0 auto;
    }

    .pagination {
        padding: 1rem;
    }

    .title {
        width: 100%;
        padding: 0 1rem 1rem;

        @media (--medium-viewport) {
            padding: 1rem 1rem 2rem;
        }
    }

    .question {
        padding: 0;
        font-family: var(--font-agrandir, 'sans-serif');
        font-size: 24px;
        font-weight: 700;
        line-height: 1.1;
        text-align: center;
        text-transform: none;

        @media (--medium-viewport) {
            font-size: 30px;
        }
    }

    .answer {
        margin-bottom: 10px;
    }

    .actions {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem 0;
        color: #FFF;
    }

    .buttons {
        &:hover {
            svg {
                transform: translateX(3px);
            }
        }
    }
}
