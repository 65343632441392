:root {
    --background: linear-gradient(0deg, #04F9A9 0%,#8DFA76 12%,#F2FB50 20%,#CCB2F3 49%,#F99EFE 75%, #BF2155 100%);
}

@keyframes pulse {
    0% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1.2);
    }
}

.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    border: 0;
    background-image: var(--background);
    background-repeat: repeat;
    color: #000;

    .content {
        z-index: 2;
        min-height: 100%;
        transform: translateZ(0);
    }

    .backgroundLayer {
        position: absolute;
        z-index: 0;
        background-image: var(--background);
        background-repeat: repeat;
        filter: hue-rotate(0deg);
        inset: 0;
        pointer-events: none;
    }

    .backgroundShadow {
        position: absolute;
        z-index: 1;

        /* background-image: linear-gradient(180deg, rgb(0 0 0 / 0), rgb(0 0 0 / 0.4));
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: 100% 20%; */
        inset: 0;
    }

    .background {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        inset: 0;
        pointer-events: none;

        .backgroundImage {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 580px;
            height: auto;
            max-height: none;
            margin: 0 auto;
            object-fit: contain;
            transform: translateX(-50%);

            &.isHome {
                right: 0;
                left: 0;
                animation: pulse 30s ease-out alternate-reverse infinite;
                transform: none;
            }

            @media (--large-viewport) {
                width: 660px;
            }

            @media (--x-large-viewport) {
                width: 768px;
            }
        }
    }

    .flare {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        animation: pulse 5s ease-out alternate-reverse infinite;
        inset: 0;
        pointer-events: none;
    }

    .hidden {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        opacity: 0;
        visibility: hidden;
    }
}
