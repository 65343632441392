.container {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    padding: 0;
    padding: 8px 15px 6px;
    border: 0;
    border: 5px solid transparent;
    border-radius: 20px;
    margin: 0;
    appearance: none;
    background: transparent;
    background-color: #000;
    color: inherit;
    cursor: pointer;
    transition: border 0.15s ease-out;

    .label {
        display: inline-block;
        padding-right: 0;
        margin-right: 0;
        background-color: transparent;
        font-family: var(--font-agrandir, 'sans-serif');
        font-size: 20px;
        font-weight: 700;
        text-transform: none;
    }

    .icon {
        padding: 0;
        margin: 0;
        margin-left: 10px;
        background-color: transparent;
    }

    svg {
        transform-origin: center;
        transition: transform 0.15s ease-out;
    }

    &:hover {
        border: 5px solid #FFF;
    }
}
