.container {
    .pagination {
        display: flex;
        padding: 1rem;
    }

    .top {
        display: flex;
        width: 100%;
        max-width: 600px;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        margin: 0 auto;
        text-align: center;
    }

    .score {
        width: 50%;
        padding: 1rem;
        font-family: var(--font-garage, 'sans-serif');
        font-size: 110px;
        font-weight: 700;
        text-align: center;
        text-shadow:
            1px 1px 0 #FFF,
            -1px -1px 0 #FFF,
            1px -1px 0 #FFF,
            -1px 1px 0 #FFF,
            1px 1px 0 #FFF;
        -webkit-text-stroke-color: #FFF;
        -webkit-text-stroke-width: 1px;
    }

    .inner {
        display: flex;
        max-width: 600px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        margin: 0 auto;
    }

    .title {
        padding: 1rem;
        font-family: var(--font-agrandir, 'sans-serif');
        font-size: 24px;
        font-weight: 700;
        line-height: 1.1;
        text-align: center;
        text-transform: none;
    }

    .description {
        padding: 1rem;
        border-radius: 20px;
        margin: 0 auto;
        background-image: linear-gradient(90deg, #BF2155, #CB4376);
        color: #FFF;
        font-family: var(--font-agrandir, 'sans-serif');
        font-size: 16px;
        font-weight: 500;
        line-height: 1.28;
        text-align: left;
        text-transform: none;

        p:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        @media (--medium-viewport) {
            font-size: 18px;
        }
    }

    .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }

    .topImage {
        display: block;
        max-width: 120px;
    }

    .actions {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem 0;
    }

    .buttons {
        border: 5px solid transparent;
        background-color: #FFF;
        color: #000;

        &:hover {
            border: 5px solid #000;

            svg {
                transform: rotate(-30deg);
            }
        }
    }

}
